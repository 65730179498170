<template>
  <div>
    <h3>{{ $t('chemicalEquationInput.headers.create') }}</h3>
    <v-combobox
      v-model="comboBoxInput"
      :label="$t('chemicalEquationInput.labels.input')"
      chips
      outlined
      deletable-chips
      dense
      :hide-no-data="!translatedInputValue"
      :search-input.sync="translatedInputValue"
      @keyup.enter="validateOnState ? checkSpeciesValidity() : updateSpecies()"
    >
      <template v-slot:no-data>
        <div class="pa-2">
          <span class="mr-2">{{ $t('chemicalEquationInput.labels.species') }}</span>
          <v-chip color="success" text-color="white">
            <chemical-latex v-if="translatedInputValue" :content="translatedInputValue" />
          </v-chip>
          <span
            v-if="validateOnState && speciesIsMissingState(translatedInputValue)"
            class="error--text d-block"
          >
            {{ $t('chemicalEquationInput.error.state') }}
          </span>
        </div>
      </template>
    </v-combobox>
    <div class="mb-4">
      <h3>{{ $t('chemicalEquationInput.headers.holding') }}</h3>
      <div style="padding: 8px; border: 1px solid #9e9e9e; border-radius: 4px">
        <span v-if="holdingArea.length === 0">{{
          $t('chemicalEquationInput.labels.holding')
        }}</span>
        <draggable :list="holdingArea" group="people" @start="drag = true" @end="drag = false">
          <v-chip
            v-for="(tag, index) in holdingArea"
            :key="tag + '-' + index"
            close
            draggable
            color="success"
            text-color="white"
            @click:close="holdingArea.splice(index, 1)"
          >
            <chemical-latex :content="tag" />
          </v-chip>
        </draggable>
      </div>
    </div>
    <v-row class="mb-2" justify="center">
      <v-col cols="5">
        <h3>{{ $t('chemicalEquationInput.headers.reactants') }}</h3>
        <div style="padding: 8px; border: 1px solid #9e9e9e; border-radius: 4px">
          <span v-if="reactants.length === 0">{{
            $t('chemicalEquationInput.labels.reactants')
          }}</span>
          <draggable :list="reactants" group="people" @start="drag = true" @end="drag = false">
            <v-chip
              v-for="(tag, index) in reactants"
              :key="tag + '-' + index"
              close
              draggable
              color="success"
              text-color="white"
              @click:close="reactants.splice(index, 1)"
            >
              <chemical-latex :content="tag" />
            </v-chip>
          </draggable>
        </div>
      </v-col>
      <v-col style="display: flex; justify-content: center" cols="2">
        <v-menu v-if="!useDefaultSymbol" offset-y>
          <template v-slot:activator="{on, attrs}">
            <v-btn
              v-bind="attrs"
              small
              class="ml-1 mr-1 inline-button"
              elevation="0"
              color="blue lighten-5"
              v-on="on"
            >
              <chemical-latex :content="yieldsSymbol" />
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="item in symbols" :key="item" @click="yieldsSymbol = item">
              <v-list-item-title>
                <chemical-latex :content="item" />
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <chemical-latex v-else content="->" />
      </v-col>
      <v-col cols="5">
        <h3>{{ $t('chemicalEquationInput.headers.products') }}</h3>
        <div style="padding: 8px; border: 1px solid #9e9e9e; border-radius: 4px">
          <span v-if="products.length === 0">{{
            $t('chemicalEquationInput.labels.products')
          }}</span>
          <draggable :list="products" group="people" @start="drag = true" @end="drag = false">
            <v-chip
              v-for="(tag, index) in products"
              :key="tag + '-' + index"
              close
              draggable
              color="success"
              text-color="white"
              @click:close="products.splice(index, 1)"
            >
              <chemical-latex :content="tag" />
            </v-chip>
          </draggable>
        </div>
      </v-col>
    </v-row>
    <h3>{{ $t('chemicalEquationInput.headers.answer') }}</h3>
    <chemical-latex v-if="youFinalTranslatedAnswer" :content="youFinalTranslatedAnswer" />
  </div>
</template>

<script>
import ChemicalLatex from '../displayers/ChemicalLatex';
import draggable from 'vuedraggable';

export default {
  name: 'ChemicalReactionInput',
  components: {ChemicalLatex, draggable},
  inheritAttrs: false,
  props: {
    value: {
      type: Object,
      default: null,
    },
    validateOnState: {
      type: Boolean,
      default: true,
    },
    useDefaultSymbol: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      stateIsInvalid: false,
      symbols: ['->', '<=>'],
      comboBoxInput: '',
      holdingArea: [],
      reactants: [],
      products: [],
      response: {
        reactants: [],
        products: [],
      },
      translatedInputValue: '',
      yieldsSymbol: '->',
    };
  },
  computed: {
    youFinalTranslatedAnswer() {
      let reactantsForFinal = this.generateReactantsOrProducts(this.reactants);
      let productsForFinal = this.generateReactantsOrProducts(this.products);
      if (reactantsForFinal || productsForFinal) {
        return `${reactantsForFinal} ${this.yieldsSymbol} ${productsForFinal}`;
      } else {
        return '';
      }
    },
  },
  watch: {
    reactants() {
      this.updateResponse();
    },
    products() {
      this.updateResponse();
    },
  },
  methods: {
    speciesIsMissingState(value) {
      return !(
        !value ||
        value.includes('(l)') ||
        value.includes('(s)') ||
        value.includes('(g)') ||
        value.includes('(aq)')
      );
    },
    updateResponse() {
      this.response = {
        products: this.products,
        reactants: this.reactants,
      };
      this.$emit('input', this.response);
    },
    generateReactantsOrProducts(reactantsOrProducts) {
      let yourFinalReactantsOrProducts = '';
      for (let i = 0; i < reactantsOrProducts.length; i++) {
        yourFinalReactantsOrProducts += reactantsOrProducts[i];
        if (i !== reactantsOrProducts.length - 1) {
          yourFinalReactantsOrProducts += ' + ';
        }
      }
      return yourFinalReactantsOrProducts;
    },
    checkSpeciesValidity() {
      if (!this.speciesIsMissingState(this.comboBoxInput)) {
        this.updateSpecies();
      }
    },
    updateSpecies() {
      this.holdingArea.push(this.comboBoxInput);
      this.translatedInputValue = '';
      this.comboBoxInput = '';
    },
  },
};
</script>
<style scoped>
.inline-button {
  font-size: 16px;
  font-family: inherit;
  text-transform: unset;
  color: #333333 !important;
}
</style>
